<template>
  <v-dialog v-model="data.dialog" persistent max-width="600px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'primary'" dark>
      <v-card-text>
        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <validation-observer
        ref="observer"
        v-slot="{ invalid }"
      >
        <v-card-title
          :class="{'form-danger': isDelete, 'form-info': isUpdate, 'form-primary': isCreate}"
        >
          <span v-if="isCreate" class="">{{ $('create') }}</span>
          <span v-if="isUpdate" class="">{{ $('update') }}</span>
          <span v-if="isDelete" class="">{{ $('delete') }}</span>
        </v-card-title>
        <v-card-text>
          <h2 v-if="isDelete && user" class="my-3">
            {{ $('delete_msg', [user.name]) }}
          </h2>
          <v-form
            v-if="!isDelete"
            ref="form"
            v-model="valid"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="الاسم"
                    rules="required"
                  >
                    <v-text-field
                      v-model="form.name"
                      v-max-length="200"
                      :error-messages="errors"
                      :label="$('name')"
                      autocomplete="new-password"
                      required
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="البريد الالكتروني"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="form.email"
                      v-max-length="200"
                      :error-messages="errors"
                      :label="$('email')"
                      autocomplete="new-password"
                      required
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.password"
                    v-max-length="200"
                    :label="$('password')"
                    autocomplete="new-password"
                    :append-icon="hidePassword ? 'visibility' : 'visibility_off'"
                    :type="hidePassword ? 'password' : 'text'"
                    required
                    @click:append="togglePassword()"
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                  <v-select
                    v-model="form.role"
                    :items="roles"
                    item-text="description"
                    item-value="name"
                    :label="$('role')"
                    required
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  sm="12"
                >
                </v-col>
                <v-col
                  :cols="imagePreview ? 9 : 12"
                >
                  <v-file-input
                    ref="file"
                    v-model="form.image"
                    type="file"
                    accept="image/png, image/jpeg, image/bmp"
                    :placeholder="$('image_select')"
                    prepend-icon="mdi-camera"
                    :label="$('image')"
                    @change="fileChanged"
                  ></v-file-input>
                </v-col>
                <v-col
                  v-if="imagePreview"
                  cols="3"
                >
                  <v-avatar
                    color=""
                    class="v-avatar-light-bg primary--text mb-1 "
                    size="100"
                    rounded
                  >
                    <v-img
                      :src="imagePreview"
                    >
                    </v-img>
                  </v-avatar>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light"
            outlined
            @click="dialogModal(false)"
          >
            {{ $t('form.cancel') }}
          </v-btn>
          <v-btn
            v-if="isCreate"
            color="primary"
            :disabled="invalid || submitLoading"
            :loading="submitLoading"
            @click="submit"
          >
            {{ $t('form.create') }}
          </v-btn>
          <v-btn
            v-if="isUpdate"
            color="info"
            :disabled="invalid || submitLoading"
            :loading="submitLoading"
            @click="submit"
          >
            {{ $t('form.save') }}
          </v-btn>
          <v-btn
            v-if="isDelete"
            color="error"
            :disabled="submitLoading"
            :loading="submitLoading"
            @click="submit"
          >
            {{ $t('form.delete') }}
          </v-btn>
        </v-card-actions>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from '@axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import toast from '@/store/toast'
import validationMixin from '@/mixins/validation-message';

export default {
  name: 'UserDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [validationMixin],
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    hidePassword: true,
    roles: [],
    imagePreview: null,
    user: null,
    form: {
      email: '',
      name: '',
      password: '',
      image: null,
      role: null,
      checkpoint_id: null,
    },
  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
    rules() {
      const rules = {};
      rules.name = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.name`)]),
      ];
      rules.role = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.role`)]),
      ];
      rules.checkpoint_id = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.checkpoint`)]),
      ];
      rules.email = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.email`)]),
        v => /.+@.+\..+/.test(v) || this.$t('form.pattern', [this.$t(`${this.translationKey}.modal.email`)]),
      ];
      rules.image = [
        v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.image`)]),
        v => (v && v.size < 10000000) || this.$t(`${this.translationKey}.modal.form.image`),
      ];
      if (this.isCreate) {
        rules.password = [
          v => !!v || this.$t('form.required', [this.$t(`${this.translationKey}.modal.password`)]),
        ];
      }

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.id) {
            const user = await axios.get(`/users/${val.id}`);
            this.user = user.data.data;
            this.form.name = this.user.name;
            this.form.email = this.user.email;
            this.form.role = this.user.role.name;
            this.form.checkpoint_id = this.user.checkpoint_id;
            if (this.user.image) {
              this.imagePreview = this.user.image.original_url;
            }
          }
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
  },
  async mounted() {
    const response = await axios.get('/roles')
    this.roles = response.data.data
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        if (this.isDelete) {
          await axios.delete(`/users/delete/${this.data.id}`);
          await toast.dispatch('success', this.$t('form.success.delete'))
          this.$emit('update:table')
          this.dialogModal(false)

          return;
        }
        if (this.$refs.form.validate() === false) {
          return;
        }
        const formData = new FormData();
        formData.append('image', this.form.image);
        formData.append('name', this.form.name);
        formData.append('email', this.form.email);
        formData.append('password', this.form.password);
        formData.append('role', this.form.role);
        formData.append('checkpoint_id', this.form.checkpoint_id);
        if (this.isCreate) {
          await axios.post('/users/create', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          await toast.dispatch('success', this.$t('form.success.create'))
        } else if (this.isUpdate) {
          await axios.post(`/users/edit/${this.data.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          await toast.dispatch('success', this.$t('form.success.update'))
        }
        this.$emit('update:table')
        this.dialogModal(false)
      } catch (error) {
        //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
      } finally {
        this.submitLoading = false;
      }
    },
    fileChanged() {
      if (!this.form.image) {
        this.imagePreview = null

        return null
      }
      const reader = new FileReader()
      reader.readAsDataURL(this.form.image)
      reader.onload = () => {
        this.imagePreview = reader.result
      }
      reader.onerror = error => {
        console.log('Error: ', error)
      }

      return null
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        email: '',
        name: '',
        password: '',
        image: null,
        role: null,
      };
      this.imagePreview = null;
      this.user = null;
    },
    togglePassword() {
      this.hidePassword = !this.hidePassword;
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
