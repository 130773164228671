import { extend, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules';
import { useUtils } from '@core/libs/i18n'

export default {
  setup() {
    setInteractionMode('eager')
    const { t } = useUtils();
    Object.keys(rules).forEach(rule => {
      extend(rule, {
        ...rules[rule],
        message: t(`rules.${rule}`),
      });
    });
  },
};
